

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Styles from "@material-ui/core/styles";

var s = {
  list: {
    width: 250
  }
};

var useStyles = Styles.makeStyles(s);

function SwipeableDrawer$Drawer(Props) {
  var close = Props.close;
  var children = Props.children;
  var classes = useStyles();
  var onClick = function (_evt) {
    return Curry._1(close, undefined);
  };
  var onKeyDown = function (evt) {
    if (evt.type !== "keydown") {
      return Curry._1(close, undefined);
    }
    var key = evt.key;
    if (key === "Tab" || key === "Shift") {
      return ;
    } else {
      return Curry._1(close, undefined);
    }
  };
  return React.createElement("div", {
              className: classes.list,
              onKeyDown: onKeyDown,
              onClick: onClick
            }, children);
}

var Drawer = {
  s: s,
  useStyles: useStyles,
  make: SwipeableDrawer$Drawer
};

export {
  Drawer ,
  
}
/* useStyles Not a pure module */
