

import * as Card from "../../toolkit/Card.bs.js";
import * as I18n from "../../i18n/I18n.bs.js";
import * as $$Text from "../../toolkit/Text.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Route from "../../Route.bs.js";
import * as Stack from "../../toolkit/Stack.bs.js";
import * as React from "react";
import * as Button from "../../toolkit/Button.bs.js";
import * as Constants from "../../Constants.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as LanguageSwitcher_Css from "./LanguageSwitcher_Css.bs.js";

function LanguageSwitcher(Props) {
  var route = Props.route;
  var locale = Props.locale;
  var match = React.useState((function () {
          return false;
        }));
  var toggleShowMenu = match[1];
  var showMenu = match[0];
  var toggleShow = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return Curry._1(toggleShowMenu, (function (param) {
                  return !showMenu;
                }));
  };
  var links = Belt_Array.map(Constants.availableLocalesJs, (function (locale$1) {
          var route$1 = Route.toRelativePath(route, locale$1);
          var language = I18n.t("locales." + locale$1);
          if (locale === locale$1) {
            return React.createElement($$Text.P.make, {
                        children: "✔ " + (String(language) + ""),
                        key: locale$1
                      });
          } else {
            return React.createElement("a", {
                        key: locale$1,
                        href: route$1
                      }, React.createElement($$Text.P.make, {
                            weight: /* bold */-1055161979,
                            children: language,
                            key: locale$1
                          }));
          }
        }));
  return React.createElement("div", {
              className: LanguageSwitcher_Css.languageSwitcher
            }, React.createElement(Button.make, {
                  label: locale,
                  color: /* Transparent */1,
                  onClick: toggleShow
                }), showMenu ? React.createElement("div", {
                    className: LanguageSwitcher_Css.languageList
                  }, React.createElement(Card.make, {
                        children: React.createElement(Stack.make, {
                              gap: /* Sm */1,
                              children: links
                            })
                      })) : null);
}

var Css;

var make = LanguageSwitcher;

export {
  Css ,
  make ,
  
}
/* Card Not a pure module */
