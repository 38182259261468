

import * as React from "react";
import * as Box_Css from "./Box_Css.bs.js";

function Box(Props) {
  var gap = Props.gap;
  var children = Props.children;
  return React.createElement("div", {
              className: Box_Css.gap(gap)
            }, children);
}

var Css;

var make = Box;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
