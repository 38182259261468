

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";
import * as Pervasives from "bs-platform/lib/es6/pervasives.js";

function getStyles(buttonColor) {
  if (buttonColor) {
    return /* :: */[
            Css.backgroundColor(/* transparent */582626130),
            /* :: */[
              Css.color(StyleVars.Color.black),
              /* :: */[
                Css.hover(/* :: */[
                      Css.backgroundColor(StyleVars.Color.black),
                      /* :: */[
                        Css.color(StyleVars.Color.white),
                        /* [] */0
                      ]
                    ]),
                /* :: */[
                  Css.active(/* :: */[
                        Css.backgroundColor(StyleVars.Color.darkBeige),
                        /* :: */[
                          Css.color(StyleVars.Color.black),
                          /* :: */[
                            Css.boxShadow(/* none */-922086728),
                            /* [] */0
                          ]
                        ]
                      ]),
                  /* :: */[
                    Css.disabled(/* :: */[
                          Css.color(StyleVars.Color.black),
                          /* :: */[
                            Css.cursor(/* notAllowed */939907157),
                            /* :: */[
                              Css.backgroundColor(StyleVars.Color.white),
                              /* :: */[
                                Css.hover(/* :: */[
                                      Css.backgroundColor(StyleVars.Color.darkBeige),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ];
  } else {
    return /* :: */[
            Css.backgroundColor(StyleVars.Color.blue),
            /* :: */[
              Css.color(StyleVars.Color.white),
              /* :: */[
                Css.boxShadow(StyleVars.Shadows.cardShadow),
                /* :: */[
                  Css.hover(/* :: */[
                        Css.backgroundColor(StyleVars.Color.black),
                        /* :: */[
                          Css.color(StyleVars.Color.white),
                          /* [] */0
                        ]
                      ]),
                  /* :: */[
                    Css.active(/* :: */[
                          Css.backgroundColor(StyleVars.Color.darkBeige),
                          /* :: */[
                            Css.color(StyleVars.Color.black),
                            /* :: */[
                              Css.boxShadow(/* none */-922086728),
                              /* [] */0
                            ]
                          ]
                        ]),
                    /* :: */[
                      Css.disabled(/* :: */[
                            Css.color(StyleVars.Color.black),
                            /* :: */[
                              Css.cursor(/* notAllowed */939907157),
                              /* :: */[
                                Css.backgroundColor(StyleVars.Color.white),
                                /* :: */[
                                  Css.hover(/* :: */[
                                        Css.backgroundColor(StyleVars.Color.darkBeige),
                                        /* [] */0
                                      ]),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ];
  }
}

function button(buttonColor) {
  var styles = getStyles(buttonColor);
  return Curry._1(Css.style, Pervasives.$at(styles, /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* :: */[
                    Css.flexDirection(/* row */5693978),
                    /* :: */[
                      Css.justifyContent(/* center */98248149),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* :: */[
                          Css.padding(StyleVars.Gap.md),
                          /* :: */[
                            Css.margin(/* zero */-789508312),
                            /* :: */[
                              Css.borderRadius(StyleVars.Size.px4),
                              /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* :: */[
                                  Css.borderWidth(/* zero */-789508312),
                                  /* :: */[
                                    Css.fontSize(StyleVars.FontSize.md),
                                    /* :: */[
                                      Css.fontWeight(/* bold */-1055161979),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]));
}

export {
  getStyles ,
  button ,
  
}
/* Css Not a pure module */
