

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

var languageSwitcher = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var languageList = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.bottom(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.minWidth(/* `px */[
                  25096,
                  100
                ]),
            /* :: */[
              Css.transforms(/* :: */[
                    Css.translateY(/* `percent */[
                          -119887163,
                          102
                        ]),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

export {
  languageSwitcher ,
  languageList ,
  
}
/* languageSwitcher Not a pure module */
