

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";

function textAlignMobile(align) {
  return Curry._1(Css.style, /* :: */[
              Css.media(StyleVars.Media.mobile, /* :: */[
                    Css.textAlign(align),
                    /* [] */0
                  ]),
              /* [] */0
            ]);
}

function textAlign(align) {
  return Curry._1(Css.style, /* :: */[
              Css.textAlign(align),
              /* [] */0
            ]);
}

function fontWeight(weight) {
  return Curry._1(Css.style, /* :: */[
              Css.fontWeight(weight >= 42488412 ? /* normal */812216871 : /* bold */-1055161979),
              /* [] */0
            ]);
}

function fontSize(size) {
  return Curry._1(Css.style, /* :: */[
              Css.fontSize(StyleVars.FontSize.toPx(size)),
              /* [] */0
            ]);
}

function text(d) {
  return Curry._1(Css.style, /* :: */[
              Css.display(d >= 423610969 ? (
                      d >= 888960333 ? /* block */888960333 : /* inline */423610969
                    ) : (
                      d >= -147785676 ? /* inlineBlock */-147785676 : /* none */-922086728
                    )),
              /* :: */[
                Css.margin(/* zero */-789508312),
                /* :: */[
                  Css.color(StyleVars.Color.black),
                  /* [] */0
                ]
              ]
            ]);
}

export {
  textAlignMobile ,
  textAlign ,
  fontWeight ,
  fontSize ,
  text ,
  
}
/* Css Not a pure module */
