

import * as Cn from "../components/Cn.bs.js";
import * as React from "react";
import * as Stack_Css from "./Stack_Css.bs.js";

function Stack(Props) {
  var gap = Props.gap;
  var layout = Props.layout;
  var align = Props.align;
  var vAlign = Props.vAlign;
  var fullHeightOpt = Props.fullHeight;
  var children = Props.children;
  var fullHeight = fullHeightOpt !== undefined ? fullHeightOpt : false;
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    Stack_Css.container(gap),
                    /* :: */[
                      Stack_Css.stack,
                      /* :: */[
                        Cn.mapSome(layout, Stack_Css.stackLayout),
                        /* :: */[
                          Cn.mapSome(align, Stack_Css.stackAlign),
                          /* :: */[
                            Cn.mapSome(vAlign, Stack_Css.stackVAlign),
                            /* :: */[
                              Cn.ifTrue(Stack_Css.fullHeight, fullHeight),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var Css;

var make = Stack;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
