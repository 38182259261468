

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

var gatsbyLink = Curry._1(Css.style, /* :: */[
      Css.textDecoration(/* none */-922086728),
      /* [] */0
    ]);

export {
  gatsbyLink ,
  
}
/* gatsbyLink Not a pure module */
