

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";

function gapSizeToPx(size) {
  switch (size) {
    case /* Sm */0 :
        return StyleVars.Gap.sm;
    case /* Md */1 :
        return StyleVars.Gap.md;
    case /* Lg */2 :
        return StyleVars.Gap.lg;
    case /* Xl */3 :
        return StyleVars.Gap.xl;
    
  }
}

function getSizePx(gap) {
  return gapSizeToPx(gap[0]);
}

function getGap(g) {
  var gapSizePx = gapSizeToPx(g[0]);
  switch (g.tag | 0) {
    case /* Four */0 :
        return Css.padding(gapSizePx);
    case /* TopBottom */1 :
        return Css.padding2(gapSizePx, /* zero */-789508312);
    case /* LeftRight */2 :
        return Css.padding2(/* zero */-789508312, gapSizePx);
    
  }
}

function gap(g) {
  return Curry._1(Css.style, /* :: */[
              getGap(g),
              /* [] */0
            ]);
}

export {
  gapSizeToPx ,
  getSizePx ,
  getGap ,
  gap ,
  
}
/* Css Not a pure module */
