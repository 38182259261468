

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

function injectGlobal(param) {
  Curry._2(Css.$$global, "html", /* :: */[
        Css.boxSizing(/* borderBox */9307263),
        /* [] */0
      ]);
  Curry._2(Css.$$global, "*, *:before, *:after", /* :: */[
        Css.boxSizing(/* inherit_ */-601204732),
        /* [] */0
      ]);
  Curry._2(Css.$$global, "p, h1, h2, h3, h4, h5, h6", /* :: */[
        Css.margin(/* zero */-789508312),
        /* [] */0
      ]);
  Curry._2(Css.$$global, "ul, ol, dd", /* :: */[
        Css.margin(/* zero */-789508312),
        /* :: */[
          Css.padding(/* zero */-789508312),
          /* :: */[
            Css.unsafe("list-style", "none"),
            /* [] */0
          ]
        ]
      ]);
  Curry._2(Css.$$global, "li", /* :: */[
        Css.marginBottom(/* `px */[
              25096,
              6
            ]),
        /* [] */0
      ]);
  return Curry._2(Css.$$global, "button", /* :: */[
              Css.backgroundColor(/* transparent */582626130),
              /* :: */[
                Css.borderWidth(/* zero */-789508312),
                /* :: */[
                  Css.padding(/* zero */-789508312),
                  /* :: */[
                    Css.cursor(/* pointer */-786317123),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

export {
  injectGlobal ,
  
}
/* Css Not a pure module */
