

import * as Box from "./Box.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as React from "react";
import * as Card_Css from "./Card_Css.bs.js";

function Card(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Card_Css.card,
              name: "Card"
            }, React.createElement(Box.make, {
                  gap: /* Four */Block.__(0, [/* Lg */2]),
                  children: children
                }));
}

var Css;

var make = Card;

export {
  Css ,
  make ,
  
}
/* Box Not a pure module */
