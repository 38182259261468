

import * as Cn from "../components/Cn.bs.js";
import * as React from "react";
import * as Text_Css from "./Text_Css.bs.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function Text$Base(Props) {
  var align = Props.align;
  var alignMobile = Props.alignMobile;
  var fontSize = Props.fontSize;
  var displayOpt = Props.display;
  var tagOpt = Props.tag;
  var weight = Props.weight;
  var children = Props.children;
  var display = displayOpt !== undefined ? displayOpt : /* block */888960333;
  var tag = tagOpt !== undefined ? tagOpt : "span";
  return React.createElement(tag, {
              className: Cn.make(/* :: */[
                    Text_Css.text(display),
                    /* :: */[
                      Cn.mapSome(weight, Text_Css.fontWeight),
                      /* :: */[
                        Text_Css.fontSize(fontSize),
                        /* :: */[
                          Cn.mapSome(align, Text_Css.textAlign),
                          /* :: */[
                            Cn.mapSome(alignMobile, Text_Css.textAlignMobile),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var Base = {
  make: Text$Base
};

function Text$H1(Props) {
  var align = Props.align;
  var fontSizeOpt = Props.fontSize;
  var alignMobile = Props.alignMobile;
  var display = Props.display;
  var weight = Props.weight;
  var children = Props.children;
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : /* Xl */3;
  var tmp = {
    fontSize: fontSize,
    tag: "h1",
    children: children
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (alignMobile !== undefined) {
    tmp.alignMobile = Caml_option.valFromOption(alignMobile);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement(Text$Base, tmp);
}

var H1 = {
  make: Text$H1
};

function Text$H2(Props) {
  var align = Props.align;
  var fontSizeOpt = Props.fontSize;
  var alignMobile = Props.alignMobile;
  var display = Props.display;
  var weight = Props.weight;
  var children = Props.children;
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : /* Lg */2;
  var tmp = {
    fontSize: fontSize,
    tag: "h2",
    children: children
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (alignMobile !== undefined) {
    tmp.alignMobile = Caml_option.valFromOption(alignMobile);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement(Text$Base, tmp);
}

var H2 = {
  make: Text$H2
};

function Text$H3(Props) {
  var align = Props.align;
  var fontSizeOpt = Props.fontSize;
  var alignMobile = Props.alignMobile;
  var display = Props.display;
  var weight = Props.weight;
  var children = Props.children;
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : /* Md */1;
  var tmp = {
    fontSize: fontSize,
    tag: "h3",
    children: children
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (alignMobile !== undefined) {
    tmp.alignMobile = Caml_option.valFromOption(alignMobile);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement(Text$Base, tmp);
}

var H3 = {
  make: Text$H3
};

function Text$Div(Props) {
  var align = Props.align;
  var fontSizeOpt = Props.fontSize;
  var alignMobile = Props.alignMobile;
  var display = Props.display;
  var weight = Props.weight;
  var children = Props.children;
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : /* Sm */0;
  var tmp = {
    fontSize: fontSize,
    tag: "div",
    children: children
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (alignMobile !== undefined) {
    tmp.alignMobile = Caml_option.valFromOption(alignMobile);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement(Text$Base, tmp);
}

var Div = {
  make: Text$Div
};

function Text$P(Props) {
  var align = Props.align;
  var fontSizeOpt = Props.fontSize;
  var alignMobile = Props.alignMobile;
  var display = Props.display;
  var weight = Props.weight;
  var children = Props.children;
  var fontSize = fontSizeOpt !== undefined ? fontSizeOpt : /* Sm */0;
  var tmp = {
    fontSize: fontSize,
    tag: "p",
    children: children
  };
  if (align !== undefined) {
    tmp.align = Caml_option.valFromOption(align);
  }
  if (alignMobile !== undefined) {
    tmp.alignMobile = Caml_option.valFromOption(alignMobile);
  }
  if (display !== undefined) {
    tmp.display = Caml_option.valFromOption(display);
  }
  if (weight !== undefined) {
    tmp.weight = Caml_option.valFromOption(weight);
  }
  return React.createElement(Text$Base, tmp);
}

var P = {
  make: Text$P
};

var Css;

export {
  Css ,
  Base ,
  H1 ,
  H2 ,
  H3 ,
  Div ,
  P ,
  
}
/* react Not a pure module */
