

import * as Box from "../toolkit/Box.bs.js";
import * as Row from "../toolkit/Row.bs.js";
import * as I18n from "../i18n/I18n.bs.js";
import * as $$Text from "../toolkit/Text.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Route from "../Route.bs.js";
import * as Stack from "../toolkit/Stack.bs.js";
import * as React from "react";
import * as Constants from "../Constants.bs.js";
import * as GlobalCss from "./GlobalCss.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as GatsbyLink from "../toolkit/GatsbyLink.bs.js";
import * as Layout_Css from "./Layout_Css.bs.js";
import * as SwipeableDrawer from "../components/mui/SwipeableDrawer/SwipeableDrawer.bs.js";
import * as LanguageSwitcher from "../components/language_switcher/LanguageSwitcher.bs.js";
import * as List from "@material-ui/core/List";
import * as Menu from "@material-ui/icons/Menu";
import * as ListItem from "@material-ui/core/ListItem";
import * as IconButton from "@material-ui/core/IconButton";
import * as SwipeableDrawer$1 from "@material-ui/core/SwipeableDrawer";

GlobalCss.injectGlobal(undefined);

function Layout$Drawer$Content(Props) {
  var locale = Props.locale;
  var route = Props.route;
  return React.createElement(List.default, {
              children: Belt_Array.map(Belt_Array.map(Route.allRoutes, (function (r) {
                          return Route.routeDetails(locale, r);
                        })), (function (param) {
                      var code = param.code;
                      var text = I18n.t(code + ".link_text");
                      var isActive = route === param.route;
                      return React.createElement("a", {
                                  key: code,
                                  href: param.path
                                }, React.createElement(ListItem.default, {
                                      button: true,
                                      children: React.createElement($$Text.Div.make, {
                                            weight: isActive ? /* bold */-1055161979 : /* regular */42488412,
                                            children: text
                                          })
                                    }));
                    }))
            });
}

var Content = {
  make: Layout$Drawer$Content
};

function Layout$Drawer(Props) {
  var route = Props.route;
  var locale = Props.locale;
  var match = React.useState((function () {
          return false;
        }));
  var toggle = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(IconButton.default, {
                  onClick: (function (param) {
                      return Curry._1(toggle, (function (param) {
                                    return true;
                                  }));
                    }),
                  children: React.createElement(Menu.default, { })
                }), React.createElement(SwipeableDrawer$1.default, {
                  open: match[0],
                  onOpen: (function (param) {
                      console.log("OnOpen");
                      
                    }),
                  onClose: (function (param) {
                      return Curry._1(toggle, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(SwipeableDrawer.Drawer.make, {
                        close: (function (param) {
                            return Curry._1(toggle, (function (param) {
                                          return false;
                                        }));
                          }),
                        children: React.createElement(Layout$Drawer$Content, {
                              locale: locale,
                              route: route
                            })
                      })
                }));
}

var Drawer = {
  Content: Content,
  make: Layout$Drawer
};

function Layout$Header(Props) {
  var route = Props.route;
  var locale = Props.locale;
  return React.createElement("header", {
              className: Layout_Css.header
            }, React.createElement("div", {
                  className: Layout_Css.logo
                }, React.createElement(Layout$Drawer, {
                      route: route,
                      locale: locale
                    }), React.createElement(GatsbyLink.make, {
                      to: Route.toRelativePath(/* Index */0, locale),
                      children: React.createElement($$Text.Div.make, {
                            align: /* center */98248149,
                            fontSize: /* Md */1,
                            weight: /* bold */-1055161979,
                            children: "GenRandom"
                          })
                    })), React.createElement(LanguageSwitcher.make, {
                  route: route,
                  locale: locale
                }));
}

var Header = {
  make: Layout$Header
};

function Layout$Footer(Props) {
  var route = Props.route;
  var locale = Props.locale;
  return React.createElement("footer", {
              className: Layout_Css.footer
            }, React.createElement(Box.make, {
                  gap: /* Four */Block.__(0, [/* Md */1]),
                  children: React.createElement(Stack.make, {
                        gap: /* Md */2,
                        children: null
                      }, React.createElement(Row.make, {
                            gap: /* Xl */3,
                            align: /* top */5793429,
                            children: null
                          }, React.createElement(Stack.make, {
                                gap: /* Lg */3,
                                children: null
                              }, React.createElement("strong", undefined, I18n.t("footer.randomizers")), React.createElement("ul", undefined, Belt_Array.map(Belt_Array.map(Route.allExceptIndex, (function (r) {
                                              return Route.routeDetails(locale, r);
                                            })), (function (param) {
                                          var code = param.code;
                                          var text = I18n.t(code + ".link_text");
                                          return React.createElement("li", {
                                                      key: code
                                                    }, React.createElement("a", {
                                                          href: param.path
                                                        }, text));
                                        })))), React.createElement(Stack.make, {
                                gap: /* Md */2,
                                children: null
                              }, React.createElement("strong", undefined, I18n.t("footer.online_tools")), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement("a", {
                                            href: "https://jsonhelper.com",
                                            rel: "noopener noreferrer",
                                            target: "_blank"
                                          }, I18n.t("footer.jsonhelper"))))), React.createElement(Stack.make, {
                                gap: /* Md */2,
                                children: null
                              }, React.createElement("strong", undefined, I18n.t("footer.language")), React.createElement("ul", undefined, Belt_Array.mapWithIndex(Constants.availableLocalesJs, (function (index, locale$1) {
                                          var route$1 = Route.toRelativePath(route, locale$1);
                                          var language = I18n.t("locales." + locale$1);
                                          if (locale === locale$1) {
                                            return React.createElement("li", {
                                                        key: locale$1 + String(index)
                                                      }, "✔ " + (String(language) + ""));
                                          } else {
                                            return React.createElement("li", {
                                                        key: locale$1 + String(index)
                                                      }, React.createElement("a", {
                                                            href: route$1
                                                          }, language));
                                          }
                                        }))))), React.createElement("div", undefined, "GenRandom 2022"))
                }));
}

var Footer = {
  make: Layout$Footer
};

function Layout(Props) {
  var route = Props.route;
  var locale = Props.locale;
  var children = Props.children;
  return React.createElement("div", {
              className: Layout_Css.page
            }, React.createElement(Layout$Header, {
                  route: route,
                  locale: locale
                }), React.createElement(Box.make, {
                  gap: /* Four */Block.__(0, [/* Xl */3]),
                  children: React.createElement(Stack.make, {
                        gap: /* Lg */3,
                        children: children
                      })
                }), React.createElement(Layout$Footer, {
                  route: route,
                  locale: locale
                }));
}

var Css;

var make = Layout;

var $$default = Layout;

export {
  Css ,
  Drawer ,
  Header ,
  Footer ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
