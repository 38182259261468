

import * as React from "react";
import * as LimitedWidthContainer_Css from "./LimitedWidthContainer_Css.bs.js";

function LimitedWidthContainer(Props) {
  var maxWidth = Props.maxWidth;
  var fullWidthMobile = Props.fullWidthMobile;
  var children = Props.children;
  return React.createElement("div", {
              className: LimitedWidthContainer_Css.limitedWidthContainer(maxWidth, fullWidthMobile)
            }, children);
}

var Css;

var make = LimitedWidthContainer;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
