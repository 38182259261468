


var availableLocalesJs = [
  "en",
  "ru"
];

export {
  availableLocalesJs ,
  
}
/* No side effect */
