

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";

function getMaxWidth(maxWidth) {
  switch (maxWidth) {
    case /* Px360 */0 :
        return StyleVars.Size.Section.sm;
    case /* Px600 */1 :
        return StyleVars.Size.Section.md;
    case /* Px840 */2 :
        return StyleVars.Size.Section.lg;
    
  }
}

function limitedWidthContainer(maxContentWidth, fullWidthMobile) {
  return Curry._1(Css.style, /* :: */[
              Css.width(/* `percent */[
                    -119887163,
                    100
                  ]),
              /* :: */[
                Css.maxWidth(getMaxWidth(maxContentWidth)),
                /* :: */[
                  Css.margin2(/* zero */-789508312, /* auto */-1065951377),
                  /* :: */[
                    Css.media(StyleVars.Media.mobile, /* :: */[
                          Css.maxWidth(fullWidthMobile ? /* unset */653193961 : getMaxWidth(maxContentWidth)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

export {
  getMaxWidth ,
  limitedWidthContainer ,
  
}
/* Css Not a pure module */
