

import * as Route from "../../Route.bs.js";
import * as React from "react";
import * as Constants from "../../Constants.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactHelmet from "react-helmet";

function Head(Props) {
  var title = Props.title;
  var canonicalUrl = Props.canonicalUrl;
  var description = Props.description;
  var lang = Props.lang;
  var children = Props.children;
  var route = Props.route;
  var canonical = React.createElement("link", {
        href: Route.toAbsoluteUrl(route, lang),
        rel: "canonical"
      });
  var xDefault = React.createElement("link", {
        href: Route.toAbsoluteUrl(route, "en"),
        hrefLang: "x-default",
        rel: "alternate"
      });
  var alternates = Belt_Array.map(Constants.availableLocalesJs, (function (lang) {
          return React.createElement("link", {
                      key: lang,
                      href: Route.toAbsoluteUrl(route, lang),
                      hrefLang: lang,
                      rel: "alternate"
                    });
        }));
  var facebookVerification = React.createElement("meta", {
        content: "v0v967nwnort44du7x42nnq57ldgb1",
        name: "facebook-domain-verification"
      });
  return React.createElement(ReactHelmet.Helmet, {
              children: null
            }, React.createElement("html", {
                  lang: lang
                }), React.createElement("title", undefined, title), React.createElement("meta", {
                  content: description,
                  name: "description"
                }), React.createElement("meta", {
                  content: "ca9b7cc7eb23e2518421dc7903eeb434334b0aab75e03456fc1086534381f2d7",
                  name: "ahrefs-site-verification"
                }), React.createElement("meta", {
                  content: "d731e0eda6b7f37fc097f50aa38cfe210bbd3b5ca66c458db381f63cc342fd36",
                  name: "ahrefs-site-verification"
                }), React.createElement("meta", {
                  content: "687c463f7d251067f1dd1824c0a49a75e96bbe920cbffc27294209b539168915",
                  name: "ahrefs-site-verification"
                }), React.createElement("link", {
                  href: "https://genrandom.com/favicon.ico",
                  rel: "icon",
                  type: "image/x-icon"
                }), canonicalUrl !== undefined ? React.createElement("link", {
                    href: canonicalUrl,
                    rel: "canonical"
                  }) : null, xDefault, canonical, alternates, facebookVerification, children !== undefined ? Caml_option.valFromOption(children) : null);
}

var Helmet;

var $$Array;

var make = Head;

export {
  Helmet ,
  $$Array ,
  make ,
  
}
/* Route Not a pure module */
