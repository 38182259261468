

import * as Constants from "./Constants.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";

function toCode(route) {
  switch (route) {
    case /* Index */0 :
        return "index";
    case /* Names */1 :
        return "names";
    case /* Numbers */2 :
        return "numbers";
    case /* Cats */3 :
        return "cats";
    case /* ListRandomizer */4 :
        return "list_randomizer";
    
  }
}

function toRelativePath(route, locale) {
  var prefix;
  switch (locale) {
    case "en" :
        prefix = "";
        break;
    case "ru" :
        prefix = "/ru";
        break;
    default:
      prefix = "";
  }
  var path;
  switch (route) {
    case /* Index */0 :
        path = "/";
        break;
    case /* Names */1 :
        path = "/names/";
        break;
    case /* Numbers */2 :
        path = "/numbers/";
        break;
    case /* Cats */3 :
        path = "/cats/";
        break;
    case /* ListRandomizer */4 :
        path = "/list-randomizer/";
        break;
    
  }
  return prefix + path;
}

function toAbsoluteUrl(route, locale) {
  var relativePath = toRelativePath(route, locale);
  return "https://genrandom.com" + relativePath;
}

var allRoutes = [
  /* Index */0,
  /* Names */1,
  /* Numbers */2,
  /* Cats */3,
  /* ListRandomizer */4
];

var allExceptIndex = [
  /* Names */1,
  /* Numbers */2,
  /* Cats */3,
  /* ListRandomizer */4
];

var generatorRoutes = Belt_Array.sliceToEnd(allRoutes, 1);

function routeDetails(locale, route) {
  return {
          route: route,
          code: toCode(route),
          path: toRelativePath(route, locale),
          absoluteUrl: toAbsoluteUrl(route, locale)
        };
}

function generatorRoutesDetailed(locale) {
  return Belt_Array.map(generatorRoutes, (function (route) {
                return routeDetails(locale, route);
              }));
}

var allPaths = Belt_Array.concatMany(Belt_Array.map(allRoutes, (function (route) {
            return Belt_Array.map(Constants.availableLocalesJs, (function (locale) {
                          return toRelativePath(route, locale);
                        }));
          })));

export {
  toCode ,
  toRelativePath ,
  toAbsoluteUrl ,
  allRoutes ,
  allExceptIndex ,
  generatorRoutes ,
  routeDetails ,
  generatorRoutesDetailed ,
  allPaths ,
  
}
/* generatorRoutes Not a pure module */
