

import * as React from "react";
import * as Gatsby from "gatsby";
import * as GatsbyLink_Css from "./GatsbyLink_Css.bs.js";

function GatsbyLink(Props) {
  var _to = Props.to;
  var children = Props.children;
  return React.createElement(Gatsby.Link, {
              to: _to,
              className: GatsbyLink_Css.gatsbyLink,
              children: children
            });
}

var Css;

var make = GatsbyLink;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
