

import * as Cn from "../components/Cn.bs.js";
import * as React from "react";
import * as Row_Css from "./Row_Css.bs.js";

function Row(Props) {
  var gapOpt = Props.gap;
  var layout = Props.layout;
  var fitToContentOpt = Props.fitToContent;
  var alignOpt = Props.align;
  var children = Props.children;
  var gap = gapOpt !== undefined ? gapOpt : /* Md */1;
  var fitToContent = fitToContentOpt !== undefined ? fitToContentOpt : false;
  var align = alignOpt !== undefined ? alignOpt : /* center */98248149;
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    Row_Css.row(gap),
                    /* :: */[
                      Cn.mapSome(layout, Row_Css.rowLayout),
                      /* :: */[
                        Row_Css.rowAlign(align),
                        /* :: */[
                          Cn.ifTrue(Row_Css.rowFitToContent, fitToContent),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, children);
}

var Css;

var make = Row;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
