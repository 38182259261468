import i18next from "i18next"
import en from "./locales/en.yml"
import ru from "./locales/ru.yml"

export const availableLocales = ["en", "ru"]

const locales = [
  ["en", en],
  ["ru", ru],
]

const translations = locales.reduce((acc, locale) => {
  const [localeName, translations] = locale

  const resourse = {
    [localeName]: {
      translation: translations,
    },
  }
  return { ...acc, ...resourse }
}, {})

i18next.init({
  lng: "en",
  resources: translations,
})

export default i18next
