

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "../toolkit/StyleVars.bs.js";

var page = Curry._1(Css.style, /* :: */[
      Css.minHeight(/* `vh */[
            26418,
            100
          ]),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.backgroundColor(StyleVars.Color.beige),
            /* [] */0
          ]
        ]
      ]
    ]);

var header = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.backgroundColor(StyleVars.Color.white),
          /* :: */[
            Css.minHeight(/* `rem */[
                  5691738,
                  3.5
                ]),
            /* :: */[
              Css.boxShadow(StyleVars.Shadows.cardShadow),
              /* :: */[
                Css.paddingLeft(StyleVars.Size.px22),
                /* :: */[
                  Css.paddingRight(StyleVars.Size.px22),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var logo = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* [] */0
      ]
    ]);

var footer = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.minHeight(/* `rem */[
                  5691738,
                  2.5
                ]),
            /* :: */[
              Css.backgroundColor(StyleVars.Color.darkBeige),
              /* :: */[
                Css.marginTop(/* auto */-1065951377),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

export {
  page ,
  header ,
  logo ,
  footer ,
  
}
/* page Not a pure module */
