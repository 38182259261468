

import * as Pervasives from "bs-platform/lib/es6/pervasives.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.js";

function min(w) {
  return "(min-width: " + (String(w) + "px)");
}

function max(w) {
  return "(max-width: " + (String(w) + "px)");
}

var mobile = max(768);

var desktop = min(769);

var tablet = min(769) + (" and " + max(1279));

var wide = min(1280);

function getMobile(vars) {
  if (vars) {
    return vars[0];
  } else {
    return Pervasives.failwith("vars are not provided");
  }
}

function getDesktop(vars) {
  if (!vars) {
    return Pervasives.failwith("vars are not provided");
  }
  var match = vars[1];
  if (match) {
    return match[0];
  } else {
    return vars[0];
  }
}

var Media = {
  mobileBreakpoint: 768,
  minDesktop: 769,
  desktopBreakpoint: 1280,
  min: min,
  max: max,
  mobile: mobile,
  desktop: desktop,
  tablet: tablet,
  wide: wide,
  getMobile: getMobile,
  getDesktop: getDesktop
};

var white = /* `hex */[
  5194459,
  "fff"
];

var black = /* `hex */[
  5194459,
  "000"
];

var black30a = /* `rgba */[
  -878128972,
  /* tuple */[
    0,
    0,
    0,
    /* `num */[
      5496390,
      0.30
    ]
  ]
];

var BasicColor_whiteHover = /* `hex */[
  5194459,
  "e0e0e0"
];

var BasicColor_whiteActive = /* `hex */[
  5194459,
  "ccc"
];

var BasicColor_white10a = /* `rgba */[
  -878128972,
  /* tuple */[
    255,
    255,
    255,
    /* `num */[
      5496390,
      0.10
    ]
  ]
];

var BasicColor_white20a = /* `rgba */[
  -878128972,
  /* tuple */[
    255,
    255,
    255,
    /* `num */[
      5496390,
      0.20
    ]
  ]
];

var BasicColor_white30a = /* `rgba */[
  -878128972,
  /* tuple */[
    255,
    255,
    255,
    /* `num */[
      5496390,
      0.30
    ]
  ]
];

var BasicColor_white40a = /* `rgba */[
  -878128972,
  /* tuple */[
    255,
    255,
    255,
    /* `num */[
      5496390,
      0.40
    ]
  ]
];

var BasicColor_white50a = /* `rgba */[
  -878128972,
  /* tuple */[
    255,
    255,
    255,
    /* `num */[
      5496390,
      0.50
    ]
  ]
];

var BasicColor_black10a = /* `rgba */[
  -878128972,
  /* tuple */[
    0,
    0,
    0,
    /* `num */[
      5496390,
      0.10
    ]
  ]
];

var BasicColor_black20a = /* `rgba */[
  -878128972,
  /* tuple */[
    0,
    0,
    0,
    /* `num */[
      5496390,
      0.20
    ]
  ]
];

var BasicColor_black40a = /* `rgba */[
  -878128972,
  /* tuple */[
    0,
    0,
    0,
    /* `num */[
      5496390,
      0.40
    ]
  ]
];

var BasicColor_black50a = /* `rgba */[
  -878128972,
  /* tuple */[
    0,
    0,
    0,
    /* `num */[
      5496390,
      0.50
    ]
  ]
];

var BasicColor = {
  white: white,
  whiteHover: BasicColor_whiteHover,
  whiteActive: BasicColor_whiteActive,
  white10a: BasicColor_white10a,
  white20a: BasicColor_white20a,
  white30a: BasicColor_white30a,
  white40a: BasicColor_white40a,
  white50a: BasicColor_white50a,
  black: black,
  black10a: BasicColor_black10a,
  black20a: BasicColor_black20a,
  black30a: black30a,
  black40a: BasicColor_black40a,
  black50a: BasicColor_black50a
};

var Color_beige = /* `hex */[
  5194459,
  "f3f0ec"
];

var Color_darkBeige = /* `hex */[
  5194459,
  "e7e2dc"
];

var Color_blue = /* `hex */[
  5194459,
  "4d90fe"
];

var Color = {
  shadow: black30a,
  white: white,
  black: black,
  beige: Color_beige,
  darkBeige: Color_darkBeige,
  blue: Color_blue
};

var px10 = /* `px */[
  25096,
  10
];

var px14 = /* `px */[
  25096,
  14
];

var px18 = /* `px */[
  25096,
  18
];

var px22 = /* `px */[
  25096,
  22
];

var px26 = /* `px */[
  25096,
  26
];

var Section_sm = /* `px */[
  25096,
  360
];

var Section_md = /* `px */[
  25096,
  600
];

var Section_lg = /* `px */[
  25096,
  840
];

var Section = {
  sm: Section_sm,
  md: Section_md,
  lg: Section_lg
};

var Size_px4 = /* `px */[
  25096,
  4
];

var Size = {
  px4: Size_px4,
  px10: px10,
  px14: px14,
  px18: px18,
  px22: px22,
  px26: px26,
  Section: Section
};

var cardShadow = Css_Legacy_Core.Shadow.box(/* `px */[
      25096,
      0
    ], /* `px */[
      25096,
      2
    ], /* `px */[
      25096,
      4
    ], undefined, false, black30a);

var Shadows = {
  cardShadow: cardShadow
};

function toPx(t) {
  switch (t) {
    case /* Sm */0 :
        return px14;
    case /* Md */1 :
        return px18;
    case /* Lg */2 :
        return px22;
    case /* Xl */3 :
        return px26;
    
  }
}

var FontSize = {
  sm: px14,
  md: px18,
  lg: px22,
  xl: px26,
  toPx: toPx
};

var Gap = {
  sm: px10,
  md: px14,
  lg: px18,
  xl: px22
};

export {
  Media ,
  BasicColor ,
  Color ,
  Size ,
  Shadows ,
  FontSize ,
  Gap ,
  
}
/* mobile Not a pure module */
