

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";

function getGapSizePx(gapSize) {
  switch (gapSize) {
    case /* None */0 :
        return /* zero */-789508312;
    case /* Sm */1 :
        return StyleVars.Size.px10;
    case /* Md */2 :
        return StyleVars.Size.px14;
    case /* Lg */3 :
        return StyleVars.Size.px18;
    
  }
}

var stack = Curry._1(Css.style, /* :: */[
      Css.gridAutoFlow(/* row */5693978),
      /* :: */[
        Css.selector("& > *", /* :: */[
              Css.minWidth(/* zero */-789508312),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

function stackLayout(layout) {
  return Curry._1(Css.style, /* :: */[
              Css.label("stackLayout"),
              /* :: */[
                Css.gridTemplateRows(layout),
                /* [] */0
              ]
            ]);
}

function stackAlign(align) {
  var tmp;
  switch (align) {
    case /* Left */0 :
        tmp = "start";
        break;
    case /* Right */1 :
        tmp = "end";
        break;
    case /* Center */2 :
        tmp = "center";
        break;
    
  }
  return Curry._1(Css.style, /* :: */[
              Css.unsafe("justify-items", tmp),
              /* [] */0
            ]);
}

function stackVAlign(align) {
  var tmp;
  switch (align) {
    case /* Top */0 :
        tmp = /* start */67859554;
        break;
    case /* Bottom */1 :
        tmp = /* end_ */-1021944796;
        break;
    case /* Center */2 :
        tmp = /* center */98248149;
        break;
    
  }
  return Curry._1(Css.style, /* :: */[
              Css.alignItems(tmp),
              /* [] */0
            ]);
}

var fullHeight = Curry._1(Css.style, /* :: */[
      Css.height(/* `percent */[
            -119887163,
            100
          ]),
      /* [] */0
    ]);

function container(gap) {
  return Curry._1(Css.style, /* :: */[
              Css.label("stack"),
              /* :: */[
                Css.display(/* grid */-999565626),
                /* :: */[
                  Css.gridGap(getGapSizePx(gap)),
                  /* :: */[
                    Css.media(StyleVars.Media.mobile, /* :: */[
                          Css.gridGap(getGapSizePx(gap)),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

export {
  getGapSizePx ,
  stack ,
  stackLayout ,
  stackAlign ,
  stackVAlign ,
  fullHeight ,
  container ,
  
}
/* stack Not a pure module */
