

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";
import * as CssPrelude from "./CssPrelude.bs.js";

function getGap(gap) {
  switch (gap) {
    case /* Sm */0 :
        return StyleVars.Gap.sm;
    case /* Md */1 :
        return StyleVars.Gap.md;
    case /* Lg */2 :
        return StyleVars.Gap.lg;
    case /* Xl */3 :
        return StyleVars.Gap.xl;
    
  }
}

var rowFitToContent = Curry._1(Css.style, /* [] */0);

function row(gap) {
  return Curry._1(Css.style, /* :: */[
              Css.label("row"),
              /* :: */[
                Css.display(/* grid */-999565626),
                /* :: */[
                  Css.gridGap(getGap(gap)),
                  /* :: */[
                    Css.gridAutoFlow(/* column */-963948842),
                    /* :: */[
                      Css.alignItems(/* center */98248149),
                      /* :: */[
                        CssPrelude.selectSelf(rowFitToContent)(/* :: */[
                              Css.unsafe("width", "fit-content"),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function rowLayout(layout) {
  return Curry._1(Css.style, /* :: */[
              Css.gridTemplateColumns(layout),
              /* [] */0
            ]);
}

function rowAlign(align) {
  return Curry._1(Css.style, /* :: */[
              Css.alignItems(align !== -162316795 ? (
                      align >= 98248149 ? (
                          align >= 287825029 ? /* baseline */287825029 : /* center */98248149
                        ) : (
                          align >= 5793429 ? /* start */67859554 : /* end_ */-1021944796
                        )
                    ) : /* stretch */-162316795),
              /* [] */0
            ]);
}

export {
  getGap ,
  rowFitToContent ,
  row ,
  rowLayout ,
  rowAlign ,
  
}
/* rowFitToContent Not a pure module */
