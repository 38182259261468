

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function make(cns) {
  return $$String.concat(" ", Belt_List.keep(cns, (function (x) {
                    return x !== "";
                  })));
}

function ifTrue(cn, x) {
  if (x) {
    return cn;
  } else {
    return "";
  }
}

function ifSome(cn, x) {
  if (x !== undefined) {
    return cn;
  } else {
    return "";
  }
}

function mapSome(x, fn) {
  if (x !== undefined) {
    return Curry._1(fn, Caml_option.valFromOption(x));
  } else {
    return "";
  }
}

function unpack(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "";
  }
}

export {
  make ,
  ifTrue ,
  ifSome ,
  mapSome ,
  unpack ,
  
}
/* No side effect */
