

import * as Css from "bs-css-emotion/src/Css.js";

function selectSelf(selector) {
  var partial_arg = "&." + selector;
  return (function (param) {
      return Css.selector(partial_arg, param);
    });
}

function selectParent(selector) {
  var partial_arg = "." + (String(selector) + " > &");
  return (function (param) {
      return Css.selector(partial_arg, param);
    });
}

function selectAncestor(selector) {
  var partial_arg = "." + (String(selector) + " &");
  return (function (param) {
      return Css.selector(partial_arg, param);
    });
}

function selectPrevious(selector) {
  var partial_arg = "." + (String(selector) + " + &");
  return (function (param) {
      return Css.selector(partial_arg, param);
    });
}

function selectModifier(selector, modifier) {
  return "" + (String(selector) + ("" + (String(modifier) + "")));
}

function selectActive(selector) {
  return "" + (String(selector) + ":active");
}

function selectDisabled(selector) {
  return "" + (String(selector) + ":disabled");
}

function selectFocus(selector) {
  return "" + (String(selector) + ":focus");
}

function selectHover(selector) {
  return "" + (String(selector) + ":hover");
}

export {
  selectSelf ,
  selectParent ,
  selectAncestor ,
  selectPrevious ,
  selectModifier ,
  selectActive ,
  selectDisabled ,
  selectFocus ,
  selectHover ,
  
}
/* Css Not a pure module */
