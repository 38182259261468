

import * as Constants from "../Constants.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as I18next from "../../utils/i18n/i18next";

var i18next = I18next.default;

function t(param) {
  return i18next.t(param);
}

function changeLanguage(locale) {
  i18next.changeLanguage(locale);
  
}

function fromString(locale) {
  switch (locale) {
    case "en" :
        return /* EN */0;
    case "ru" :
        return /* RU */1;
    default:
      return /* EN */0;
  }
}

function toString(locale) {
  if (locale) {
    return "ru";
  } else {
    return "en";
  }
}

var availableLocales = Belt_Array.map(Constants.availableLocalesJs, fromString);

export {
  i18next ,
  t ,
  changeLanguage ,
  fromString ,
  toString ,
  availableLocales ,
  
}
/* i18next Not a pure module */
