

import * as Css from "bs-css-emotion/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as StyleVars from "./StyleVars.bs.js";

var card = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.backgroundColor(StyleVars.Color.white),
          /* :: */[
            Css.borderRadius(StyleVars.Size.px4),
            /* :: */[
              Css.boxShadow(StyleVars.Shadows.cardShadow),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

export {
  card ,
  
}
/* card Not a pure module */
