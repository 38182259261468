

import * as React from "react";
import * as Button_Css from "./Button_Css.bs.js";

function Button(Props) {
  var label = Props.label;
  var disabledOpt = Props.disabled;
  var colorOpt = Props.color;
  var onClick = Props.onClick;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var color = colorOpt !== undefined ? colorOpt : /* Default */0;
  return React.createElement("button", {
              className: Button_Css.button(color),
              disabled: disabled,
              onClick: onClick
            }, label);
}

var Css;

var make = Button;

export {
  Css ,
  make ,
  
}
/* react Not a pure module */
